

































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MLocationItem: () =>
      import("@/components/molecules/globalTools/m-location-item.vue"),
    MLocationPrint: () =>
      import("@/components/molecules/globalTools/m-location-print.vue"),
  },

  props: {
    type: {
      type: String,
      required: false,
      default: "list",
    },
    search: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root }) {
    const state = reactive({
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "image", text: "", width: 1 },
        { value: "name", text: root.$tc("layout.misc.name") },
        { value: "city", text: root.$tc("layout.misc.city") },
        { value: "category", text: root.$tc("layout.misc.category") },
        {
          value: "languageVersion",
          text: root.$tc("layout.misc.languageVersion"),
        },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: "",
      searchTimeout: undefined as any,
      printDialog: false,
      printDialogItem: [],
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("location", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            search: state.search || undefined,
          },
        })
        .then(({ data: { locations, total } }) => {
          state.empty = false;
          state.items = locations.map((location: any, index: number) => ({
            id: index,
            name: "",
            city: "",
            ...location,
          }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`location/${item.id}`)
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    watch(
      () => props.search,
      (newSearch) => {
        state.search = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const paginationLength = computed(() =>
      Math.ceil(state.total / state.options.itemsPerPage)
    );
    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/location/`
    );

    const copyItem = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`location/${id}`)
        .then(({ data: { location } }) => {
          state.loading = true;

          const name = prompt(
            root.$tc("panel.globalTools.location.enterNewLocationName"),
            `${location.name} (kopia)`
          );

          if (name) {
            axiosInstance
              .post("location", {
                name,
                languageVersion: location.languageVersion || undefined,
                category: location.category || undefined,
                description: location.description || undefined,
                image: location.image?.id || undefined,
                city: location.city,
                street: location.address || undefined,
                postalCode: location.postalCode || undefined,
                latitude: location.lat || undefined,
                longitude: location.lng || undefined,
                phoneNumber: location.phoneNumber || undefined,
                email: location.email || undefined,
                website: location.website || undefined,
              })
              .then(fetchData)
              .catch((error) => {
                console.log(error);
                alert(root.$tc("panel.globalTools.location.newLocationError"));
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            state.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
        });
    };

    const printDialog = (item: any) => {
      state.printDialogItem = Object.assign({}, item);
      state.printDialog = true;
    };

    return {
      state,
      fetchData,
      paginationLength,
      deleteItem,
      filesUrl,
      copyItem,
      printDialog,
    };
  },
});
